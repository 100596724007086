<template>
              <!-- 头像 -->
            <v-menu
              v-model="user"
              :close-on-content-click="false"
              location="end"
              class="rounded-xl-bk"
            >
              <template v-slot:activator="{ props }">
                <v-btn icon :elevation="3" v-bind="props">
                  <v-avatar>
                    <v-img
                      :alt="website.user.username"
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                    ></v-img>
                  </v-avatar>
                </v-btn>
              </template>

              <v-card min-width="300" class="rounded-xl-bk" :elevation="5">
                <v-list>
                  <v-list-item
                    prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg"
                    :subtitle="website.user.description"
                    :title="website.user.username"
                  >
                    <template v-slot:append>
                      <v-btn
                        icon="mdi-account-cog-outline"
                        variant="text"
                        :elevation="3"
                        v-if="false"
                      ></v-btn>
                    </template>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                  <v-list-item>
                    <template v-slot:append>
                      <v-list-item-action start>
                        <!-- <v-checkbox-btn :model-value="isActive"></v-checkbox-btn> -->
                        <!-- <v-switch
                        v-model="website.syncDark"
                        color="primary"
                        @click="setSyncDark"
                        hide-details
                      ></v-switch> -->
                      <v-radio-group inline v-model="radios" @change="changeRadios" hide-details :color="radios == '1' ? '#bfdbfe' : radios == '2' ? '#1e3a8a': '#3b82f6'">
                        <v-radio label="浅色" value="1"></v-radio>
                        <v-radio label="深色" value="2"></v-radio>
                        <v-radio label="系统" value="3"></v-radio>
                      </v-radio-group>
                      </v-list-item-action>
                      
                    </template>
                    <template v-slot:title>
                      外观
                    </template>
                  </v-list-item>
                  

                  <v-list-item>
                    <v-switch
                      v-model="hints"
                      color="purple"
                      label="Enable hints"
                      hide-details
                      v-if="false"
                    ></v-switch>
                  </v-list-item>
                </v-list>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    class="w-full rounded-xl-bk"
                    :elevation="5"
                    @click="website.logout()"
                  >
                    退出登录
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
</template>

<script setup>
import { ref } from "vue";
const user = ref(false);

const website = useWebsiteStore();
const radios = ref('1')
onMounted(()=>{
  if(website.syncDark){
    radios.value = '3'
  }else{
    radios.value = website.dark ? '2' : '1'
  }
})

const changeRadios = (e) => {
  switch ( radios.value) {
    case '1':
      website.setSyncDark(false)
      website.setDark(false)
      break;
    case '2':
      website.setSyncDark(false)
      website.setDark(true)
      break;
    default:
      website.setSyncDark(true)
      break;
  }
  // website.setDark(true)
  // website.setSyncDark(syncDark.value)
}


</script>
